import { FormInstance } from "antd/lib/form";
import { EventName } from "./component/event_name";
import { TabsKeyEnum } from "@src/pages/ab-test-explore/enum/tabs-key.enum";
import React from "react";

import { EventParams } from "./component/event-params";
import { ParamValues } from "./component/param_values";
import { UserEventHistogram } from "./component/event-breakdown/user-event-histogram";
import { Checkbox, Form } from "antd";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { ApplyEventButton } from "./component/apply-event-button";

const EventBreakdown: React.FC<{ form: FormInstance }> = ({ form }) => {
  return (
    <div>
      <div className="flex flex-wrap ">
        <EventName form={form} />
        <div className="px-2" />
        <EventParams form={form} />
        <div className="px-2" />
        <ParamValues form={form} />
        <div className="ml-8">
          <ApplyEventButton form={form} />
        </div>
      </div>
      <UserEventHistogram form={form} chartKey={TabsKeyEnum.EVENT_BREAKDOWN} />
      <Form.Item name={ChartFormFieldsEnum.IS_ALLOW_GET_USER_EVENT_ENGAGEMENT} hidden valuePropName="checked">
        <Checkbox />
      </Form.Item>
    </div>
  );
};
export const EventBreakdownMemo = React.memo(EventBreakdown);
