import { FormInstance } from "antd/lib/form";
import { Collapse } from "antd";
import { UserEngagementTime, UserPlayTime, UserSessionCount } from "./component/time-in-app";
import { InfoCircleOutlined } from "@ant-design/icons";
import { EngagementTabsKeyEnum } from "@src/pages/ab-test-explore/enum/tabs-key.enum";
import { ChartAction } from "../action";
import { ChartWrapperContextProvider } from "@src/contexts/ab-testing/chart-wrapper-context";
import React from "react";
import { LocationTabsView, useUpdateTabsViewParams } from "@src/hooks/ab-testing/use-update-tabs-view-params";
const TimeInApp: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { items, onChange } = useUpdateTabsViewParams<string | string[]>({
    location: LocationTabsView.TIME_IN_APP,
  });

  return (
    <ChartWrapperContextProvider>
      <Collapse
        className="mt-4"
        activeKey={items}
        onChange={onChange}
        items={[
          {
            key: EngagementTabsKeyEnum.PLAY_TIME,
            label: "Play time",
            children: <UserPlayTime form={form} chartKey={EngagementTabsKeyEnum.PLAY_TIME} />,
            extra: <ChartAction tabKey={EngagementTabsKeyEnum.PLAY_TIME} tabName="User play time" form={form} />,
          },
          {
            key: EngagementTabsKeyEnum.TIME_IN_APP,
            label: "Time in app",
            children: <UserEngagementTime form={form} chartKey={EngagementTabsKeyEnum.TIME_IN_APP} />,
            extra: <InfoCircleOutlined />,
          },
          {
            key: EngagementTabsKeyEnum.SESSION_COUNT,
            label: "Session count",
            children: <UserSessionCount form={form} chartKey={EngagementTabsKeyEnum.SESSION_COUNT} />,
            extra: <InfoCircleOutlined />,
          },
        ]}
      />
    </ChartWrapperContextProvider>
  );
};
export const TimeInAppMemo = React.memo(TimeInApp);
