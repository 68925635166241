import { useCubeQuery } from "@cubejs-client/react";
import { SelectWithSpinAndCustomMaxTag } from "@src/components/select/SelectWithCustomMaxTag";
import { FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { Form, Spin } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import React from "react";

export const VariantFilter: React.FC<{ form: FormInstance }> = ({ form }) => {
  const abaIdWatch = Form.useWatch(FilterFormFieldsEnum.ABA_ID, form);
  const productTokenWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_TOKEN, form);
  const isHasAbaId = abaIdWatch && typeof abaIdWatch === "string";

  const { resultSet, isLoading } = useCubeQuery(
    {
      dimensions: ["user_ab_testing.exp_group"],
      filters: [
        {
          member: "user_ab_testing.aba_id",
          operator: "equals",
          values: [abaIdWatch?.toString()],
        },
      ],
    },
    {
      skip: !productTokenWatch || !isHasAbaId,
    },
  );

  const options = React.useMemo(() => {
    return (
      resultSet?.rawData().map((item: any) => {
        return {
          label: "Variant: " + item["user_ab_testing.exp_group"],
          value: item["user_ab_testing.exp_group"],
        };
      }) ?? []
    );
  }, [resultSet?.rawData()]);

  React.useEffect(() => {
    if (!form.getFieldValue(FilterFormFieldsEnum.IS_ABA_ID_CHANGED)) return;
    form.setFieldValue(
      FilterFormFieldsEnum.VARIANTS,
      options?.map((item: any) => item.value),
    );
  }, [options]);

  React.useEffect(() => {
    if (isHasAbaId) return;
    form.setFieldsValue({ [FilterFormFieldsEnum.VARIANTS]: [] });
  }, [isHasAbaId]);

  return (
    <Spin spinning={isLoading}>
      <Form.Item label="Variants" name={FilterFormFieldsEnum.VARIANTS} className="ml-2">
        <SelectWithSpinAndCustomMaxTag
          disabled={!isHasAbaId}
          mode="multiple"
          allowClear
          placeholder="Select Variant"
          options={isLoading || !isHasAbaId ? [] : options}
          style={{
            width: 300,
          }}
        />
      </Form.Item>
    </Spin>
  );
};
