import { ChartFormFieldsEnum, FilterFormFieldsEnum, TabsViewParamsEnum } from "@src/pages/ab-test-explore/enum";

const isValidCustomDate = (customDate: string[]): boolean => {
  return Array.isArray(customDate) && customDate.length === 2;
};
export const checkConfigFilterForm = (filterForm: Record<string, any>): void => {
  Object.keys(filterForm).forEach((key) => {
    switch (key) {
      case FilterFormFieldsEnum.AMA_APP_IDS:
      case FilterFormFieldsEnum.PLATFORM:
      case FilterFormFieldsEnum.VARIANTS: {
        if (!Array.isArray(filterForm[key])) {
          throw new Error(`Invalid ${key} params!`);
        }
        break;
      }
      case FilterFormFieldsEnum.CUSTOM_INSTALL_DATE: {
        if (filterForm[key] && !isValidCustomDate(filterForm[key])) {
          throw new Error(`Invalid ${key} params!`);
        }
        break;
      }
    }
  });
};
export const checkConfigChartForm = (chartForm: Record<string, any>): void => {
  Object.keys(chartForm).forEach((key) => {
    switch (key) {
      case ChartFormFieldsEnum.PARAM_VALUE:
      case ChartFormFieldsEnum.EVENT_PARAM_OPTIONS: {
        if (!Array.isArray(chartForm[key])) {
          throw new Error(`Invalid ${key} params!`);
        }
        break;
      }
      case ChartFormFieldsEnum.MAX_DAY_DIFF: {
        if (Number.isInteger(chartForm[key])) {
          throw new Error(`Invalid ${key} params!`);
        }
        break;
      }
      case ChartFormFieldsEnum.CUSTOM_TIME_RANGE: {
        if (chartForm[key] && !isValidCustomDate(chartForm[key])) {
          throw new Error(`Invalid ${key} params!`);
        }
        break;
      }
    }
  });
};

export const checkTabsConfigParams = (tabsConfig: Record<string, any>): void => {
  Object.keys(tabsConfig).forEach((key) => {
    switch (key) {
      case TabsViewParamsEnum.MAIN_TABS:
      case TabsViewParamsEnum.SUB_TABS:
      case TabsViewParamsEnum.INNER_SUB_TABS:
        if (!tabsConfig[key]) {
          throw new Error(`Invalid ${key} params!`);
        }
        break;
      case TabsViewParamsEnum.COLLAPSE_ITEMS:
        if (!Array.isArray(tabsConfig[key])) {
          throw new Error(`Invalid ${key} params!`);
        }
        break;
      default:
        throw new Error(`Invalid params!`);
    }
  });
};
