/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import React, { useCallback, useState } from "react";
import { isValidTimeRange } from "@src/util/time-form";
import { useABContext } from "@src/contexts/ab-testing/ab-context";
import { getGroupFieldValue } from "@src/util/ab-testing/params";
import dayjs from "dayjs";

interface SubmitButtonProps {
  form: FormInstance<any>;
}
export const SubmitButton: React.FC<SubmitButtonProps> = ({ form }) => {
  const { updateSearchUrl } = useABContext();

  const [currentFields, setCurrentFields] = useState<any>({});

  const productWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);
  const productToken = Form.useWatch(FilterFormFieldsEnum.PRODUCT_TOKEN, form);
  const abaIdWatch = Form.useWatch(FilterFormFieldsEnum.ABA_ID, form);
  const variantWatch = Form.useWatch(FilterFormFieldsEnum.VARIANTS, form);
  const installDateWatch = Form.useWatch(FilterFormFieldsEnum.INSTALL_DATE, form);
  const customInstallDateWatch = Form.useWatch(FilterFormFieldsEnum.CUSTOM_INSTALL_DATE, form);
  const platformWatch = Form.useWatch(FilterFormFieldsEnum.PLATFORM, form);

  const isHasAbaId = abaIdWatch && abaIdWatch?.length > 0;
  const isHasPlatform = Array.isArray(platformWatch) && platformWatch?.length > 0;
  const isValidInstallDate = isValidTimeRange(installDateWatch, customInstallDateWatch);

  const enoughFieldsRequired =
    !!productWatch && !!productToken && isHasAbaId && isHasPlatform && (installDateWatch ? isValidInstallDate : true);

  const isFieldChange =
    JSON.stringify(currentFields) !==
    JSON.stringify({
      [FilterFormFieldsEnum.PRODUCT_CODE]: productWatch,
      [FilterFormFieldsEnum.ABA_ID]: abaIdWatch,
      [FilterFormFieldsEnum.VARIANTS]: variantWatch,
      [FilterFormFieldsEnum.INSTALL_DATE]: installDateWatch,
      [FilterFormFieldsEnum.CUSTOM_INSTALL_DATE]: customInstallDateWatch,
      [FilterFormFieldsEnum.PLATFORM]: platformWatch,
    });

  const isDisable = !enoughFieldsRequired || !isFieldChange;

  const getInitValuesChart = () => {
    const chartForm = getGroupFieldValue("chartForm") ?? {};
    return {
      ...chartForm,
      [ChartFormFieldsEnum.CUSTOM_TIME_RANGE]: chartForm?.[ChartFormFieldsEnum.CUSTOM_TIME_RANGE]?.map((item: string) =>
        dayjs(item),
      ),
    };
  };

  const handleClick = useCallback(() => {
    form.validateFields().then((e) => {
      const isFirst = form.getFieldValue(FilterFormFieldsEnum.IS_FIRST_APPLIED);
      updateSearchUrl(
        undefined,
        !isFirst
          ? {
              chartForm: getInitValuesChart(),
            }
          : undefined,
      );
      form.setFieldValue(FilterFormFieldsEnum.IS_FIRST_APPLIED, true);
      form.submit();
      setCurrentFields({
        [FilterFormFieldsEnum.PRODUCT_CODE]: productWatch,
        [FilterFormFieldsEnum.ABA_ID]: abaIdWatch,
        [FilterFormFieldsEnum.VARIANTS]: variantWatch,
        [FilterFormFieldsEnum.INSTALL_DATE]: installDateWatch,
        [FilterFormFieldsEnum.CUSTOM_INSTALL_DATE]: customInstallDateWatch,
        [FilterFormFieldsEnum.PLATFORM]: platformWatch,
      });
    });
  }, [
    abaIdWatch,
    JSON.stringify(customInstallDateWatch),
    form,
    installDateWatch,
    productWatch,
    JSON.stringify(variantWatch),
    JSON.stringify(platformWatch),
  ]);

  return (
    <div className="pt-7 mb-2">
      <Form.Item className="ml-2">
        <Button type="primary" disabled={isDisable} onClick={handleClick}>
          Apply
        </Button>
      </Form.Item>
    </div>
  );
};
