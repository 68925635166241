import { Card, Checkbox, Form, Spin } from "antd";
import { useCustom } from "@refinedev/core";
import { config } from "@src/config";
import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

import { AbaIdFilter, InstallDateFilter, ProductFilter, SubmitButton, VariantFilter, PlatformFilter } from "./form";
import { FormInstance } from "antd/lib/form";
import { ChartFormFieldsEnum, FilterFormFieldsEnum } from "../../enum";
import { FilterFormFields } from "../../types";
import React from "react";
import { css } from "@emotion/react";
import { ChartFormInstanceType, FilterFormInstanceType } from "@src/contexts/ab-testing/ab-context";
import { FORM_NAME } from "@src/constant/ab-testing/user-count";
import dayjs from "dayjs";
import { getGroupFieldValue } from "@src/util/ab-testing/params";

type FilterExplorationProps = {
  form: FormInstance<FilterFormInstanceType>;
  chartFormInstance: FormInstance<ChartFormInstanceType>;
};
export const FilterExploration: React.FC<FilterExplorationProps> = ({ form, chartFormInstance }) => {
  const productCodeWatch = Form.useWatch(FilterFormFieldsEnum.PRODUCT_CODE, form);

  const { data, isLoading: isLoadingGenerateToken } = useCustom({
    url: `${config.BACKEND_URL}/product/${productCodeWatch}/generate-cube-token`,
    method: "get",
    queryOptions: {
      retry: 0,
      enabled: !!productCodeWatch,
      onSuccess: (data) => {
        const token = data?.data?.jwt_token;
        if (token) {
          form.setFieldValue(FilterFormFieldsEnum.PRODUCT_TOKEN, token);
        }
      },
    },
    errorNotification: () => {
      return {
        type: "error",
        message: "There was an error fetching data",
        description: "Error",
      };
    },
  });

  const CUBE_TOKEN = data?.data?.jwt_token;
  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });

  const handleFinish = (values: FilterFormFields) => {
    chartFormInstance.setFieldsValue({
      [ChartFormFieldsEnum.FILTER_FIELDS]: values,
    });
  };

  const getInitValue = () => {
    const filterForm = getGroupFieldValue("filterForm") ?? {};
    return {
      ...filterForm,
      [FilterFormFieldsEnum.CUSTOM_INSTALL_DATE]: filterForm?.[FilterFormFieldsEnum.CUSTOM_INSTALL_DATE]?.map(
        (item: string) => dayjs(item),
      ),
      [FilterFormFieldsEnum.IS_ABA_ID_CHANGED]: false,
    };
  };

  const ref = React.useRef<HTMLDivElement>(null);
  const cardRef = React.useRef<HTMLDivElement>(null);
  const [isSticky, setSticky] = React.useState(false);
  const [parentWidth, setParentWidth] = React.useState<string | number>("100%");
  const [cardHeight, setCardHeight] = React.useState(150);

  const [direction, setDirection] = React.useState<"up" | "down">("down");
  const [windowScrollY, setWindowScrollY] = React.useState(0);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      const clientTop = ref.current?.getBoundingClientRect().top ?? 0;
      setParentWidth(ref.current?.clientWidth ?? "100%");
      setCardHeight(cardRef.current?.clientHeight ?? 150);
      if (clientTop < 0) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    });
    const resizeObserver = new ResizeObserver(() => {
      setParentWidth(ref.current?.clientWidth ?? "100%");
    });

    ref.current && resizeObserver.observe(ref.current);

    return () => {
      resizeObserver.disconnect();
      window.removeEventListener("scroll", (e) => {
        console.log("remove event scroll ", e);
      });
    };
  }, []);

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      windowScrollY < window.scrollY ? setDirection("down") : setDirection("up");
      setWindowScrollY(window.scrollY);
    });
    return () => {
      window.removeEventListener("scroll", (e) => {
        console.log("window scroll y remove event ", e);
      });
    };
  }, [windowScrollY]);

  return (
    <div
      ref={ref}
      style={{
        minHeight: cardHeight,
      }}
      css={css`
        .ant-card-body {
          padding-bottom: 0;
        }
        .ant-form-item {
          margin-bottom: 0 !important;
        }
      `}
      className="mb-4"
    >
      <Spin spinning={!!productCodeWatch && isLoadingGenerateToken}>
        <Card
          ref={cardRef}
          {...(isSticky && {
            style: {
              position: "fixed",
              top: direction === "down" && windowScrollY - cardHeight > 170 ? (cardHeight + 10) * -1 : 0,
              width: parentWidth,
              zIndex: 1000,
              transition: "top 0.2s ease-in-out",
            },
          })}
          className="overflow-x-scroll"
        >
          <CubeProvider cubeApi={cubejsApi}>
            <Form
              name={FORM_NAME.FILTER_FORM}
              form={form}
              layout="vertical"
              onFinish={handleFinish}
              initialValues={getInitValue()}
            >
              <div className="flex justify-between">
                <div className="flex flex-wrap">
                  <div className="flex mb-2">
                    <ProductFilter form={form} />
                    <AbaIdFilter form={form} />
                    <PlatformFilter form={form} />
                    <VariantFilter form={form} />
                  </div>
                  <div className="flex ">
                    <InstallDateFilter />
                    <SubmitButton form={form} />
                  </div>
                </div>
                <Form.Item name={FilterFormFieldsEnum.IS_FIRST_APPLIED} hidden valuePropName="checked">
                  <Checkbox />
                </Form.Item>
              </div>
            </Form>
          </CubeProvider>
        </Card>
      </Spin>
    </div>
  );
};
