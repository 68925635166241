import { TimeInAppMemo } from "./time-in-app";
import { EventBreakdownMemo } from "./event-breakdown";
import { Form, Tabs } from "antd";
import { ChartFormFieldsEnum, TabsKeyEnum } from "@src/pages/ab-test-explore/enum";
import { FormInstance } from "antd/lib/form";
import { Maybe } from "@src/types";
import { FilterFormFields } from "@src/pages/ab-test-explore/types";
import cubejs from "@cubejs-client/core";
import { config } from "@src/config";
import { CubeProvider } from "@cubejs-client/react";
import { ChartWrapperContextProvider } from "@src/contexts/ab-testing/chart-wrapper-context";
import { LocationTabsView, useUpdateTabsViewParams } from "@src/hooks/ab-testing/use-update-tabs-view-params";
import React from "react";
import { TimeRangeEngagement } from "./component/time-range";
import { MaxDateDiff } from "./component/max-day-diff";

type ABTestingEngagementProps = {
  form: FormInstance<any>;
};
const ABTestingEngagement: React.FC<ABTestingEngagementProps> = ({ form }) => {
  const { items, onChange } = useUpdateTabsViewParams<string>({
    location: LocationTabsView.ENGAGEMENT,
  });

  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form) as Maybe<FilterFormFields>;
  const CUBE_TOKEN = filtersFieldWatch?.product_token ?? "";
  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });

  return (
    <CubeProvider cubeApi={cubejsApi}>
      <ChartWrapperContextProvider>
        <div className="flex">
          <TimeRangeEngagement form={form} />
          <MaxDateDiff form={form} />
        </div>
        <Tabs
          activeKey={items}
          onChange={onChange}
          items={[
            {
              label: "Time in app",
              key: TabsKeyEnum.TIME_IN_APP,
              children: <TimeInAppMemo form={form} />,
            },
            {
              label: "Event breakdown",
              key: TabsKeyEnum.EVENT_BREAKDOWN,
              children: <EventBreakdownMemo form={form} />,
            },
          ]}
        />
      </ChartWrapperContextProvider>
    </CubeProvider>
  );
};
export const ABTestingEngagementMemo = React.memo(ABTestingEngagement);
