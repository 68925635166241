import { Form, Table, Tabs } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import React, { useRef } from "react";
import { config } from "@src/config";
import { ChartLayout } from "@src/pages/ab-test-explore/component/layout/chart-layout";
import { EChartWrapper } from "@src/pages/ab-test-explore/component/layout/echart-wrapper";
import { mappingQueryUserCount } from "@src/util/ab-testing/user-count/mapping-query-user-count";
import { useGetResultMultiStep } from "@src/hooks/ab-testing/use-get-result-multi-step";
import { EventHistogram } from "./chart-content/event-histogram";

import { abUsersQuery } from "@src/helpers/ab-testing/multi-step-query";

import {
  factUserEventCountQuery,
  userLevelMetricQuerySteps,
} from "@src/helpers/ab-testing/multi-step-query/engagement-metrics";

import { histogramQueryStep } from "@src/helpers/ab-testing/multi-step-query/chart-utils";
import { useDebounce } from "@src/hooks/use-debounce";
import { ChartFormFieldsEnum, TabsViewParamsEnum } from "@src/pages/ab-test-explore/enum";
import { getValueOfFiltersForm } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";
import { isValidTimeRange } from "@src/util/time-form";
import { isArrayAndNotEmpty } from "@src/util/common/array";

export const UserEventHistogram: React.FC<{ form: FormInstance; chartKey: string }> = ({ form, chartKey }) => {
  const preValuesConfig = useRef<string>();
  const timeRangeWatch = Form.useWatch(ChartFormFieldsEnum.TIME_RANGE, form);
  const customTimeRangeWatch = Form.useWatch(ChartFormFieldsEnum.CUSTOM_TIME_RANGE, form);

  const timeRangeWatchDebounce = useDebounce(timeRangeWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const customTimeRangeWatchDebounce = useDebounce(customTimeRangeWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form);
  const { productCode, variants, amaAppIds } = getValueOfFiltersForm(filtersFieldWatch);

  const maxDayDiffWatch = Form.useWatch(ChartFormFieldsEnum.MAX_DAY_DIFF, form);
  const maxDayDiffDebounce = useDebounce(maxDayDiffWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const eventNameWatch = Form.useWatch(ChartFormFieldsEnum.EVENT_NAME, form);
  const eventParamWatch = Form.useWatch(ChartFormFieldsEnum.EVENT_PARAM, form);
  const eventParamValuesWatch = Form.useWatch(ChartFormFieldsEnum.PARAM_VALUE, form);
  const isAllowed = Form.useWatch(ChartFormFieldsEnum.IS_ALLOW_GET_USER_EVENT_ENGAGEMENT, form);

  const eventNameWatchDebounce = useDebounce(eventNameWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const eventParamWatchDebounce = useDebounce(eventParamWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const eventParamValuesWatchDebounce = useDebounce(eventParamValuesWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);

  const _isValidTimeRange = isValidTimeRange(timeRangeWatchDebounce, customTimeRangeWatchDebounce);
  const descriptionEmpty = _isValidTimeRange ? "No data" : "No data. Select time range first";

  const {
    filters,
    timeDimensions,
    isSkip: _isSkip,
  } = mappingQueryUserCount(filtersFieldWatch, chartKey, TabsViewParamsEnum.SUB_TABS);
  const isSkip =
    _isSkip ||
    !isAllowed ||
    !eventNameWatchDebounce ||
    (eventParamWatchDebounce && !isArrayAndNotEmpty(eventParamValuesWatchDebounce)) ||
    !_isValidTimeRange;

  const targetAliases = ["histogram"];

  const { mutateAsync, isLoading, result } = useGetResultMultiStep(...targetAliases);

  const { dataSet } = React.useMemo(() => {
    return {
      dataSet: (result?.[targetAliases[0]] ?? []) as any,
    };
  }, [JSON.stringify(result)]);

  const { finalStep, allSteps } = userLevelMetricQuerySteps({
    productCode,
    usersQueryStepFn: abUsersQuery,
    factQueryStepFn: factUserEventCountQuery,
    usersQueryStepKWargs: { filters, timeDimensions },
    factQueryStepKWargs: {
      customTimeRange: customTimeRangeWatchDebounce,
      timeRange: timeRangeWatchDebounce,
      maxDayDiff: maxDayDiffDebounce,
      eventName: eventNameWatchDebounce,
      eventParam: eventParamWatchDebounce,
      eventParamValues: eventParamValuesWatchDebounce,
      amaAppIds,
    },
    metric: {
      name: "event_count",
      aggExpFn: (metricConditions) => {
        return `COUNTIF(${metricConditions.join(" AND ")})`;
      },
    },
  });

  const queryConfig = [
    ...allSteps,
    histogramQueryStep({
      histogramQueryOpts: {
        userMetricAlias: finalStep.alias,
        userId: "user_pseudo_id",
        groups: ["exp_group"],
        metric: "event_count",
        bucketSize: 1,
        numberOfBuckets: 50,
        customMetrics: {
          ["event_count"]: "SUM(event_count)",
        },
      },
    }),
  ];

  React.useEffect(() => {
    if (isSkip) return;
    const isConfigsChange = JSON.stringify(queryConfig) !== preValuesConfig.current;

    if (!isConfigsChange) return;

    preValuesConfig.current = JSON.stringify(queryConfig);

    mutateAsync({
      url: `${config.BACKEND_URL}/multistep_query/run`,
      method: "post",
      values: {
        configs: queryConfig,
        target_aliases: targetAliases,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryConfig), isSkip]);

  const histogramColumns = [
    {
      title: "Variant",
      dataIndex: "exp_group",
      key: "exp_group",
      defaultSortOrder: "ascend",
    },

    {
      title: "Bucket name",
      dataIndex: "bucket_name",
      key: "bucket_name",
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) => a?.bucket_idx - b?.bucket_idx,
    },
    {
      title: "Distinct users",
      dataIndex: "distinct_users",
      key: "distinct_users",
    },
    {
      title: "% Users",
      dataIndex: "distinct_users_percent",
      key: "distinct_users_percent",
    },
  ];

  return (
    <>
      <ChartLayout loading={!!isLoading}>
        <Tabs
          className="w-full h-full"
          items={[
            {
              key: "chart",
              label: "Chart",
              children: (
                <EChartWrapper isEmpty={dataSet?.length === 0} description={descriptionEmpty}>
                  <EventHistogram dataSource={dataSet} variants={variants} />
                </EChartWrapper>
              ),
            },
            {
              key: "raw-data",
              label: "Raw data",
              children: (
                <EChartWrapper isEmpty={dataSet?.length === 0} description={descriptionEmpty}>
                  <Table
                    rowKey="index"
                    sticky={{
                      offsetHeader: 0,
                    }}
                    size="small"
                    dataSource={dataSet}
                    columns={histogramColumns as any}
                    pagination={false}
                  />
                </EChartWrapper>
              ),
            },
          ]}
        />
      </ChartLayout>
    </>
  );
};
