import { prepareHistogramLayout } from "@src/util/ab-testing/time-in-app";
import ReactECharts from "echarts-for-react";
import React, { useEffect } from "react";
export const EventHistogram: React.FC<{ variants: string[] | undefined; dataSource: any[] }> = ({
  variants,
  dataSource,
}) => {
  const {
    title: histogramTitle,
    grid: histogramGrid,
    series: histogramSeries,
    xAxis: histogramXAxis,
    yAxis: histogramYAxis,
  } = prepareHistogramLayout({ variants: variants || [] });
  const chartRef = React.useRef<ReactECharts>(null);
  const option: echarts.EChartsOption = {
    title: histogramTitle,
    dataset: [
      {
        dimensions: [
          "exp_group",
          "bucket_idx",
          "bucket_name",
          "distinct_users",
          "distinct_users_percent",
          "event_count",
        ],
        source: dataSource,
      },
      ...(variants?.map((v) => {
        return {
          transform: [
            {
              type: "filter",
              config: { dimension: "exp_group", value: v },
            },

            {
              type: "sort",
              config: { dimension: "bucket_idx", order: "asc" },
            },
          ],
        };
      }) || []),
    ],
    axisPointer: {
      animation: false,

      show: true,
      snap: true,
      lineStyle: {
        type: "dashed",
      },
      label: {
        show: true,
        margin: 6,
        backgroundColor: "#556",
        color: "#fff",
      },

      link: [
        {
          xAxisId: variants?.map((variant) => `xAxis-${variant}`),
        },
      ],
    },
    dataZoom: [
      {
        type: "inside",
        xAxisIndex: variants?.map((_, idx) => idx),
        startValue: 0,
        endValue: 20,
      },
    ],
    animationDuration: 1000,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
      },
    },
    grid: histogramGrid,
    xAxis: histogramXAxis,
    yAxis: [...histogramYAxis],
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    series: histogramSeries,
  };

  useEffect(() => {
    const instance = chartRef.current?.getEchartsInstance();
    instance?.on("click", (params) => {
      const actionArgs = {
        type: "dataZoom",
        dataZoomIndex: 0,
        startValue: Math.max(0, params.dataIndex - 10),
        endValue: Math.min(params.dataIndex + 10, dataSource.length / (variants?.length || 1) - 1),
      };
      instance?.dispatchAction(actionArgs);
    });
  }, []);

  return (
    <ReactECharts ref={chartRef} option={option} style={{ height: variants ? (variants.length / 2) * 500 : 600 }} />
  );
};
