import { prepareHistogramLayout } from "@src/util/ab-testing/time-in-app";
import ReactECharts from "echarts-for-react";
export const HistogramPlayTime: React.FC<{ variants: string[] | undefined; dataSource: any[] }> = ({
  variants,
  dataSource,
}) => {
  const {
    title: histogramTitle,
    grid: histogramGrid,
    series: histogramSeries,
    xAxis: histogramXAxis,
    yAxis: histogramYAxis,
  } = prepareHistogramLayout({ variants: variants || [] });

  const option: echarts.EChartsOption = {
    title: histogramTitle,
    dataset: [
      {
        dimensions: ["exp_group", "bucket_idx", "bucket_name", "distinct_users", "distinct_users_percent"],
        source: dataSource,
      },
      ...(variants?.map((v) => {
        return {
          transform: [
            {
              type: "filter",
              config: { dimension: "exp_group", value: v },
            },

            {
              type: "sort",
              config: { dimension: "bucket_idx", order: "asc" },
            },
          ],
        };
      }) || []),
    ],
    axisPointer: {
      animation: false,

      show: true,
      snap: true,
      lineStyle: {
        type: "dashed",
      },
      label: {
        show: true,
        margin: 6,
        backgroundColor: "#556",
        color: "#fff",
      },
      link: [
        {
          xAxisId: variants?.map((variant) => `xAxis-${variant}`),
        },
      ],
    },
    animationDuration: 1000,
    tooltip: {
      trigger: "axis",
      valueFormatter: (value: any) => value + "%",
      axisPointer: {
        type: "cross",
      },
    },
    grid: histogramGrid,
    xAxis: histogramXAxis,
    yAxis: histogramYAxis,
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
      },
    },
    series: histogramSeries,
  };
  return <ReactECharts option={option} style={{ height: variants ? (variants.length / 2) * 500 : 600 }} />;
};
