export const DEFAULT_QUERY_BUILDER = {
  dimensions: [],
  measures: ["firebase.event_count"],
  filters: [
    {
      and: [
        {
          member: "firebase.event_name",
          operator: "equals",
          values: ["song_start"],
        },
      ],
    },
  ],
  timeDimensions: [
    {
      dimension: "firebase.event_timestamp",
      granularity: "day",
      dateRange: "Last 7 days",
    },
  ],
  order: [],
  limit: 100,
};

export const GRANULARITIES = [
  // {
  //   title: "w/o grouping",
  //   name: undefined,
  // },
  // {
  //   name: "second",
  //   title: "Second",
  // },
  // {
  //   name: "minute",
  //   title: "Minute",
  // },
  {
    name: "hour",
    title: "Hour",
  },
  {
    name: "day",
    title: "Day",
  },
  // {
  //   name: "week",
  //   title: "Week",
  // },
  // {
  //   name: "month",
  //   title: "Month",
  // },
  // {
  //   name: "quarter",
  //   title: "Quarter",
  // },
  // {
  //   name: "year",
  //   title: "Year",
  // },
];

export const DATE_RANGE_CUSTOM_VALUE = "custom";
export const DATE_RANGE_VALUE = {
  TODAY: "Today",
  YESTERDAY: "Yesterday",
  THIS_WEEK: "This week",
  THIS_MONTH: "This month",
  LAST_7_DAYS: "Last 7 days",
  LAST_14_DAYS: "Last 14 days",
  LAST_30_DAYS: "Last 30 days",
  LAST_WEEK: "Last week",
  LAST_MONTH: "Last month",
};
export const DATE_RANGES = [
  { title: "Custom", value: DATE_RANGE_CUSTOM_VALUE },
  { value: DATE_RANGE_VALUE.TODAY },
  { value: DATE_RANGE_VALUE.YESTERDAY },
  { value: DATE_RANGE_VALUE.THIS_WEEK },
  { value: DATE_RANGE_VALUE.THIS_MONTH },
  { value: DATE_RANGE_VALUE.LAST_7_DAYS },
  { value: DATE_RANGE_VALUE.LAST_14_DAYS },
  { value: DATE_RANGE_VALUE.LAST_30_DAYS },
  { value: DATE_RANGE_VALUE.LAST_WEEK },
  { value: DATE_RANGE_VALUE.LAST_MONTH },
];

export type IAdapterObject = {
  label: string;
  value: string;
};

export const ADAPTER_QUERY_BUILDER: Record<string, IAdapterObject> = {
  cube: {
    label: "Explorer",
    value: "cube",
  },
  "dbt-like": {
    label: "SQL",
    value: "dbt-like",
  },
};
