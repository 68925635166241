import { FormInstance } from "antd/lib/form/Form";
import React from "react";
import { UserCountByInstallDateMemo } from "./user-count-by-install-date";
import { UserCountByMediaSourceMemo } from "./user-count-by-media-source";
import Collapse from "antd/lib/collapse/Collapse";
import { ChartWrapperContextProvider } from "@src/contexts/ab-testing/chart-wrapper-context";
import { ChartFormFieldsEnum, UserDistributionTabsKeyEnum } from "@src/pages/ab-test-explore/enum";
import { UserCountByCountryCodeMemo } from "./user-count-by-country-code";
import { UserCountByGenderMemo } from "./user-count-by-gender";
import { UserCountByAgeMemo } from "./user-count-by-age";
import { CubeProvider } from "@cubejs-client/react";
import { Form } from "antd";
import { Maybe } from "@src/types";
import { FilterFormFields } from "@src/pages/ab-test-explore/types";
import cubejs from "@cubejs-client/core";
import { config } from "@src/config";
import { LocationTabsView, useUpdateTabsViewParams } from "@src/hooks/ab-testing/use-update-tabs-view-params";
import { ChartAction } from "../action";

export type UserCountItemProps = {
  form: FormInstance;
  chartKey: UserDistributionTabsKeyEnum;
};
const AbTestUserDistribution: React.FC<{ form: FormInstance }> = ({ form }) => {
  const { items, onChange } = useUpdateTabsViewParams<string | string[]>({
    location: LocationTabsView.USER_DISTRIBUTION,
  });
  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form) as Maybe<FilterFormFields>;

  const CUBE_TOKEN = filtersFieldWatch?.product_token ?? "";
  const cubejsApi = cubejs(CUBE_TOKEN, { apiUrl: config.CUBE_URL });

  return (
    <CubeProvider cubeApi={cubejsApi}>
      <ChartWrapperContextProvider>
        <Collapse
          activeKey={items}
          onChange={onChange}
          items={[
            {
              key: UserDistributionTabsKeyEnum.INSTALL_DATE,
              label: "User_count by Install date",
              children: <UserCountByInstallDateMemo form={form} chartKey={UserDistributionTabsKeyEnum.INSTALL_DATE} />,
              extra: (
                <ChartAction
                  tabKey={UserDistributionTabsKeyEnum.INSTALL_DATE}
                  tabName="User_count by Install date"
                  form={form}
                />
              ),
            },
            {
              key: UserDistributionTabsKeyEnum.MEDIA_SOURCE,
              label: "User_count by Media Source",
              children: <UserCountByMediaSourceMemo form={form} chartKey={UserDistributionTabsKeyEnum.MEDIA_SOURCE} />,
              extra: (
                <ChartAction
                  tabKey={UserDistributionTabsKeyEnum.MEDIA_SOURCE}
                  tabName="User_count by Media Source"
                  form={form}
                />
              ),
            },
            {
              key: UserDistributionTabsKeyEnum.COUNTRY_CODE,
              label: "User_count by Geo",
              children: <UserCountByCountryCodeMemo form={form} chartKey={UserDistributionTabsKeyEnum.COUNTRY_CODE} />,
              extra: (
                <ChartAction
                  tabKey={UserDistributionTabsKeyEnum.COUNTRY_CODE}
                  tabName="User_count by Geo"
                  form={form}
                />
              ),
            },
            {
              key: UserDistributionTabsKeyEnum.GENDER,
              label: "User_count by Gender",
              children: <UserCountByGenderMemo form={form} chartKey={UserDistributionTabsKeyEnum.GENDER} />,
              extra: (
                <ChartAction tabKey={UserDistributionTabsKeyEnum.GENDER} tabName="User_count by Gender" form={form} />
              ),
            },
            {
              key: UserDistributionTabsKeyEnum.AGE,
              label: "User_count by Age",
              children: <UserCountByAgeMemo form={form} chartKey={UserDistributionTabsKeyEnum.AGE} />,
              extra: <ChartAction tabKey={UserDistributionTabsKeyEnum.AGE} tabName="User_count by Age" form={form} />,
            },
          ]}
        />
      </ChartWrapperContextProvider>
    </CubeProvider>
  );
};

export const AbTestUserDistributionMemo = React.memo(AbTestUserDistribution);
