import React from "react";
import { prepareHistogramLayout } from "@src/util/ab-testing/time-in-app";
import ReactECharts from "echarts-for-react";
export const HistogramTimeApp: React.FC<{ variants: string[] | undefined; dataSource: any[] }> = ({
  variants,
  dataSource,
}) => {
  const {
    title: histogramTitle,
    grid: histogramGrid,
    series: histogramSeries,
    xAxis: histogramXAxis,
    yAxis: histogramYAxis,
  } = prepareHistogramLayout({ variants: variants || [] });

  const chartRef = React.useRef<any>();
  const option = {
    title: histogramTitle,
    dataset: [
      {
        dimensions: ["exp_group", "bucket_idx", "bucket_name", "distinct_users", "distinct_users_percent"],
        source: dataSource,
      },
      ...(variants?.map((v) => {
        return {
          transform: [
            {
              type: "filter",
              config: { dimension: "exp_group", value: v },
            },

            {
              type: "sort",
              config: { dimension: "bucket_idx", order: "asc" },
            },
          ],
        };
      }) || []),
    ],
    axisPointer: {
      animation: false,

      show: true,
      snap: true,
      lineStyle: {
        type: "dashed",
      },
      label: {
        show: true,
        margin: 6,
        backgroundColor: "#556",
        color: "#fff",
      },
      link: [
        {
          xAxisId: variants?.map((variant) => `xAxis-${variant}`),
        },
      ],
    },
    dataZoom: [
      {
        type: 'inside',
        xAxisIndex: variants?.map((_, idx) => idx),
        startValue: 0,
        endValue: 20,
      }
    ],
    animationDuration: 1000,
    tooltip: {
      trigger: "axis",
      valueFormatter: (value: number) => value + "%",
      axisPointer: {
        type: "cross",
      },
    },
    grid: histogramGrid,
    xAxis: histogramXAxis,
    yAxis: histogramYAxis,
    toolbox: {
      show: true,
      feature: {
        saveAsImage: {},
        restore: {},
      },
    },
    series: histogramSeries,
  };
  return <ReactECharts 
    // ref={(e) => { chartRef = e; }}
    ref={chartRef}
    onEvents={{
      'click': (params) => {
        console.log('Chart clicked 1', chartRef);
        console.log('Chart clicked 2', params);
        
        const actionArgs = {
          type: 'dataZoom',
          dataZoomIndex: 0,
          // startValue: dataAxis[Math.max(params.dataIndex - zoomSize / 2, 0)],
          startValue: Math.max(0, params.dataIndex - 10),
          // startValue: 0,
          endValue: Math.min(params.dataIndex + 10, dataSource.length / (variants?.length || 1) -1)
        }
        console.log('Chart clicked 3', actionArgs);
        chartRef.current.getEchartsInstance().dispatchAction(actionArgs);
      },
      // 'dataZoom': (params) => {
      //   console.log('Chart dataZoom 1', chartRef);
      //   console.log('Chart dataZoom 2', params);
        
      // }
    }} option={option} style={{ height: variants ? (variants.length / 2) * 500 : 600 }} 
  />;
};
