import { TabsKeyEnum, TabsViewParamsEnum } from "@src/pages/ab-test-explore/enum";
import {
  EngagementTabsKeyEnum,
  MonetizationTabsKeyEnum,
  UserDistributionTabsKeyEnum,
} from "@src/pages/ab-test-explore/enum/tabs-key.enum";
import React from "react";
import { createContext, useContext } from "react";
import { useABContext } from "./ab-context";

type ManageTabsContextType = {
  mainTabsActive: string;
  setMainTabsActive: React.Dispatch<React.SetStateAction<string>>;
  engagementTabsActive: string;
  setEngagementTabsActive: React.Dispatch<React.SetStateAction<string>>;
  distributionItemsPanelActive: string[];
  setDistributionItemsPanelActive: React.Dispatch<React.SetStateAction<string[]>>;
  timeAppItemsPanelActive: string[];
  setTimeAppItemsPanelActive: React.Dispatch<React.SetStateAction<string[]>>;
  eventBreakdownItemsPanelActive: string[];
  setEventBreakdownItemsPanelActive: React.Dispatch<React.SetStateAction<string[]>>;
  adsItemsPanelActive: string[];
  setAdsItemsPanelActive: React.Dispatch<React.SetStateAction<string[]>>;
  iapsItemsPanelActive: string[];
  setIapsItemsPanelActive: React.Dispatch<React.SetStateAction<string[]>>;
  monetizationTabsActive: string;
  setMonetizationTabsActive: React.Dispatch<React.SetStateAction<string>>;
};
export const ManageTabsContext = createContext({} as ManageTabsContextType);

type IProps = {
  children: React.ReactNode;
};

function checkAndGetDefaultForTabsActive<T>(validSample: T[], defaultValue: T, params: T) {
  return validSample.includes(params) ? params : defaultValue;
}

export const ManageTabsContextProvider: React.FC<IProps> = ({ children }) => {
  const { updateSearchUrl, getParamsFieldValue } = useABContext();

  const mainTabs = getParamsFieldValue(TabsViewParamsEnum.MAIN_TABS, "tabsConfig") ?? TabsKeyEnum.USER_DISTRIBUTION;
  const subTabs = getParamsFieldValue(TabsViewParamsEnum.SUB_TABS, "tabsConfig") ?? TabsKeyEnum.TIME_IN_APP;
  const collapseItems = getParamsFieldValue(TabsViewParamsEnum.COLLAPSE_ITEMS, "tabsConfig") ?? [];

  function checkAndGetDefaultForPanelActive<T>(validSample: string[], defaultValue: T) {
    if (collapseItems.length === 0) return defaultValue;
    return collapseItems?.some((item: any) => !validSample.includes(item)) ? defaultValue : collapseItems;
  }

  const initTabsKeys: Record<
    | "mainTabs"
    | "subTabsEngagement"
    | "subTabsMonetization"
    | "distribution"
    | "timeApp"
    | "eventBreakdown"
    | "ads"
    | "iaps",
    any
  > = {
    mainTabs: checkAndGetDefaultForTabsActive<string>(
      [TabsKeyEnum.USER_DISTRIBUTION, TabsKeyEnum.ENGAGEMENT, TabsKeyEnum.MONETIZATION],
      TabsKeyEnum.USER_DISTRIBUTION,
      mainTabs,
    ),
    subTabsEngagement: checkAndGetDefaultForTabsActive(
      [TabsKeyEnum.TIME_IN_APP, TabsKeyEnum.EVENT_BREAKDOWN],
      TabsKeyEnum.TIME_IN_APP,
      subTabs,
    ),
    subTabsMonetization: checkAndGetDefaultForTabsActive([TabsKeyEnum.ADS, TabsKeyEnum.IAPS], TabsKeyEnum.ADS, subTabs),
    distribution: checkAndGetDefaultForPanelActive<string[]>(
      [
        UserDistributionTabsKeyEnum.INSTALL_DATE,
        UserDistributionTabsKeyEnum.COUNTRY_CODE,
        UserDistributionTabsKeyEnum.GENDER,
        UserDistributionTabsKeyEnum.AGE,
        UserDistributionTabsKeyEnum.MEDIA_SOURCE,
      ],
      [UserDistributionTabsKeyEnum.INSTALL_DATE],
    ),
    timeApp: checkAndGetDefaultForPanelActive<string[]>(
      [EngagementTabsKeyEnum.PLAY_TIME, EngagementTabsKeyEnum.TIME_IN_APP, EngagementTabsKeyEnum.SESSION_COUNT],
      [EngagementTabsKeyEnum.PLAY_TIME],
    ),
    eventBreakdown: checkAndGetDefaultForPanelActive<string[]>(
      [EngagementTabsKeyEnum.USER_HISTOGRAM, EngagementTabsKeyEnum.EVENT_COUNT_BREAKDOWN],
      [EngagementTabsKeyEnum.USER_HISTOGRAM],
    ),
    ads: checkAndGetDefaultForPanelActive<string[]>(
      [MonetizationTabsKeyEnum.AD_VALUE, MonetizationTabsKeyEnum.AD_IMPRESSION, MonetizationTabsKeyEnum.E_CPM],
      [MonetizationTabsKeyEnum.AD_VALUE, MonetizationTabsKeyEnum.AD_IMPRESSION, MonetizationTabsKeyEnum.E_CPM],
    ),
    iaps: checkAndGetDefaultForPanelActive<string[]>(
      [MonetizationTabsKeyEnum.IAPS_VALUE, MonetizationTabsKeyEnum.PAY_RATE],
      [MonetizationTabsKeyEnum.IAPS_VALUE, MonetizationTabsKeyEnum.PAY_RATE],
    ),
  };

  //tabs active
  const [mainTabsActive, setMainTabsActive] = React.useState<string>(initTabsKeys.mainTabs);
  const [engagementTabsActive, setEngagementTabsActive] = React.useState<string>(initTabsKeys.subTabsEngagement);
  const [monetizationTabsActive, setMonetizationTabsActive] = React.useState<string>(initTabsKeys.subTabsMonetization);

  //panels active
  const [distributionItemsPanelActive, setDistributionItemsPanelActive] = React.useState<string[]>(
    initTabsKeys.distribution,
  );
  const [timeAppItemsPanelActive, setTimeAppItemsPanelActive] = React.useState<string[]>(initTabsKeys.timeApp);
  const [eventBreakdownItemsPanelActive, setEventBreakdownItemsPanelActive] = React.useState<string[]>(
    initTabsKeys.eventBreakdown,
  );

  const [adsItemsPanelActive, setAdsItemsPanelActive] = React.useState<string[]>(initTabsKeys.ads);
  const [iapsItemsPanelActive, setIapsItemsPanelActive] = React.useState<string[]>(initTabsKeys.iaps);

  React.useEffect(() => {
    if (
      !getParamsFieldValue(TabsViewParamsEnum.MAIN_TABS, "tabsConfig") &&
      !getParamsFieldValue(TabsViewParamsEnum.COLLAPSE_ITEMS, "tabsConfig")
    ) {
      updateSearchUrl([
        {
          key: TabsViewParamsEnum.MAIN_TABS,
          value: TabsKeyEnum.USER_DISTRIBUTION,
        },
        {
          key: TabsViewParamsEnum.COLLAPSE_ITEMS,
          value: [UserDistributionTabsKeyEnum.INSTALL_DATE],
        },
      ]);
    }
  }, []);

  const values = {
    mainTabsActive,
    setMainTabsActive,
    engagementTabsActive,
    setEngagementTabsActive,
    distributionItemsPanelActive,
    setDistributionItemsPanelActive,
    timeAppItemsPanelActive,
    setTimeAppItemsPanelActive,
    eventBreakdownItemsPanelActive,
    setEventBreakdownItemsPanelActive,
    adsItemsPanelActive,
    setAdsItemsPanelActive,
    iapsItemsPanelActive,
    setIapsItemsPanelActive,
    monetizationTabsActive,
    setMonetizationTabsActive,
  };
  return <ManageTabsContext.Provider value={values}>{children}</ManageTabsContext.Provider>;
};

export const useManageTabsContext = () => useContext(ManageTabsContext);
