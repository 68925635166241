import { Form, Table, Tabs } from "antd";
import { FormInstance } from "antd/lib/form/Form";
import React, { useRef } from "react";
import { config } from "@src/config";
import { ChartLayout } from "@src/pages/ab-test-explore/component/layout/chart-layout";
import { EChartWrapper } from "@src/pages/ab-test-explore/component/layout/echart-wrapper";
import { mappingQueryUserCount } from "@src/util/ab-testing/user-count/mapping-query-user-count";
import { Distribution, Histogram } from "../../type";
import { useGetResultMultiStep } from "@src/hooks/ab-testing/use-get-result-multi-step";
import { DistributionTimeApp } from "./chart-content/distribution-time-app";
import { HistogramTimeApp } from "./chart-content/histogram-time-app";
import { ColumnsType } from "antd/es/table";
import { ChartFormFieldsEnum } from "@src/pages/ab-test-explore/enum";
import { getValueOfFiltersForm } from "@src/pages/ab-test-explore/helper/get-value-of-filters-form";
import { useDebounce } from "@src/hooks/use-debounce";
import { isValidTimeRange } from "@src/util/time-form";

import { 
  abUsersQuery, 
} from "@src/helpers/ab-testing/multi-step-query";

import { 
  factUserEngagementTimeMetricQuery, userLevelMetricQuerySteps
} from "@src/helpers/ab-testing/multi-step-query/engagement-metrics";

import { 
  boxPlotQueryStep, histogramQueryStep, 
} from "@src/helpers/ab-testing/multi-step-query/chart-utils";

type EngagementDistribution = Distribution;
type EngagementHistogram = Histogram;

export const UserEngagementTime: React.FC<{ form: FormInstance; chartKey: string }> = ({ form, chartKey }) => {
  const prevValuesConfig = useRef<string>();

  const filtersFieldWatch = Form.useWatch(ChartFormFieldsEnum.FILTER_FIELDS, form);
  const { productCode, variants } = getValueOfFiltersForm(filtersFieldWatch);

  const maxDayDiffWatch = Form.useWatch(ChartFormFieldsEnum.MAX_DAY_DIFF, form);
  const timeRangeWatch = Form.useWatch(ChartFormFieldsEnum.TIME_RANGE, form);
  const customTimeRangeWatch = Form.useWatch(ChartFormFieldsEnum.CUSTOM_TIME_RANGE, form);

  const maxDayDiffDebounce = useDebounce(maxDayDiffWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const timeRangeWatchDebounce = useDebounce(timeRangeWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const customTimeRangeWatchDebounce = useDebounce(customTimeRangeWatch, config.DEBOUNCE_TIME.MEDIUM_DELAY);
  const { amaAppIds } = getValueOfFiltersForm(filtersFieldWatch);
  const { filters, timeDimensions, isSkip: _isSkip } = mappingQueryUserCount(filtersFieldWatch, chartKey);
  const _isValidTimeRange = isValidTimeRange(timeRangeWatchDebounce, customTimeRangeWatchDebounce);
  const isSkip = _isSkip || !_isValidTimeRange;

  const descriptionEmpty = _isValidTimeRange ? "No data" : "No data. Select time range first";

  const targetAliases = ["box-plot", "histogram"]

  const { mutateAsync, isLoading, result } = useGetResultMultiStep(...targetAliases);

  const { dataSet, histogramSet } = React.useMemo(() => {
    return {
      dataSet: (result?.[targetAliases[0]] ?? []) as EngagementDistribution[],
      histogramSet: (result?.[targetAliases[1]] ?? []) as EngagementHistogram[],
    };
    /* why dont we cache by query config?*/
  }, [result]);

  const { finalStep, allSteps } = userLevelMetricQuerySteps({
    productCode,
    usersQueryStepFn: abUsersQuery,
    factQueryStepFn: factUserEngagementTimeMetricQuery,
    usersQueryStepKWargs: {filters, timeDimensions},
    factQueryStepKWargs: {
      customTimeRange: customTimeRangeWatchDebounce, 
      timeRange: timeRangeWatchDebounce,
      maxDayDiff: maxDayDiffDebounce,
      amaAppIds: amaAppIds
    },
    metric: {
      name: "total_engagement_time",
      aggExpFn: (metricConditions) => {
          return `SUM(IF(${metricConditions.join(" AND ")}, total_engagement_time/1000, 0))`
      },
    }
  })

  const queryConfig = [
    ...allSteps,
    boxPlotQueryStep({
      boxPlotQueryOpts: {
        userMetricAlias: finalStep.alias,
        userId: "user_pseudo_id",
        groups: ["exp_group"],
        metric: "total_engagement_time"
      }
    }),
    histogramQueryStep({
      histogramQueryOpts: {
        userMetricAlias: finalStep.alias,
        userId: "user_pseudo_id",
        groups: ["exp_group"],
        metric: "total_engagement_time",
        bucketSize: 50,
        numberOfBuckets: 50,
      }
    })
  ];

  React.useEffect(() => {
    if (isSkip) return;

    const isConfigsChange = JSON.stringify(queryConfig) !== prevValuesConfig.current;

    if (!isConfigsChange) return;

    prevValuesConfig.current = JSON.stringify(queryConfig);

    mutateAsync({
      url: `${config.BACKEND_URL}/multistep_query/run`,
      method: "post",
      values: {
        configs: queryConfig,
        target_aliases: targetAliases,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(queryConfig), isSkip]);
  const transformedDatasetDistribution: any[] = [];
  const dataTable: any[] = [];

  dataSet.forEach((r, index) => {
    transformedDatasetDistribution.push([r.exp_group, r.min, r.q1, r.median, r.q3, r.max]);
    dataTable.push({
      key: index,
      variant: r.exp_group,
      min: r.min,
      q1: r.q1,
      median: r.median,
      q3: r.q3,
      max: r.max,
    });
  });

  const distributionColumns: ColumnsType = [
    {
      title: "Variant",
      dataIndex: "variant",
      key: "variant",
    },

    {
      title: "Min",
      dataIndex: "min",
      key: "min",
    },
    {
      title: "Q1",
      dataIndex: "q1",
      key: "q1",
    },
    {
      title: "Median",
      dataIndex: "median",
      key: "median",
    },
    {
      title: "Q3",
      dataIndex: "q3",
      key: "q3",
    },
    {
      title: "Max",
      dataIndex: "max",
      key: "max",
    },
  ];

  const histogramColumns: ColumnsType = [
    {
      title: "Variant",
      dataIndex: "exp_group",
      key: "exp_group",
      defaultSortOrder: "ascend",
    },

    {
      title: "Bucket name",
      dataIndex: "bucket_name",
      key: "bucket_name",
      defaultSortOrder: "ascend",
      sorter: (a: any, b: any) => a?.bucket_idx - b?.bucket_idx,
    },
    {
      title: "Distinct users",
      dataIndex: "distinct_users",
      key: "distinct_users",
    },
    {
      title: "% Users",
      dataIndex: "distinct_users_percent",
      key: "distinct_users_percent",
    },
  ];

  return (
    <>
      <ChartLayout loading={!!isLoading}>
        <Tabs
          className="w-full h-full"
          items={[
            {
              key: "chart",
              label: "Chart",
              children: (
                <EChartWrapper isEmpty={dataSet?.length === 0} description={descriptionEmpty}>
                  <DistributionTimeApp dataSource={transformedDatasetDistribution} />
                </EChartWrapper>
              ),
            },
            {
              key: "raw-data",
              label: "Raw data",
              children: (
                <EChartWrapper isEmpty={dataSet?.length === 0} description={descriptionEmpty}>
                  <Table
                    rowKey="index"
                    sticky={{
                      offsetHeader: 0,
                    }}
                    size="small"
                    dataSource={dataTable}
                    columns={distributionColumns as any}
                    pagination={false}
                  />
                </EChartWrapper>
              ),
            },
          ]}
        />
      </ChartLayout>
      <ChartLayout loading={!!isLoading}>
        <Tabs
          className="w-full h-full"
          items={[
            {
              key: "chart",
              label: "Chart",
              children: (
                <EChartWrapper isEmpty={histogramSet?.length === 0} description={descriptionEmpty}>
                  <HistogramTimeApp variants={variants} dataSource={histogramSet} />
                </EChartWrapper>
              ),
            },
            {
              key: "raw-data",
              label: "Raw data",
              children: (
                <EChartWrapper isEmpty={histogramSet?.length === 0} description={descriptionEmpty}>
                  <Table
                    rowKey="index"
                    sticky={{
                      offsetHeader: 0,
                    }}
                    size="small"
                    dataSource={histogramSet}
                    columns={histogramColumns as any}
                    pagination={false}
                  />
                </EChartWrapper>
              ),
            },
          ]}
        />
      </ChartLayout>
    </>
  );
};
